
import {computed, defineComponent, ref} from "vue";
import ActionLogCards from "@/views/entity/ActionLogCards.vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import {useActionLogStore} from "@/store/actionlog.store";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "ActionLogs",
  components: {HelpEmbed, InfoBox, ActionLogCards},
  props: {
    spaceId: {type: String}
  },
  setup(props) {
    const filters = ref({isSpace: true, spaceId: props.spaceId});
    const actionLogStore = useActionLogStore();
    const page = computed(() => actionLogStore.page)
    return {
      page,
      ...PinaFilterObjects(actionLogStore, filters.value, ['user'])
    }
  }
})
